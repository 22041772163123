import PropTypes from 'prop-types';
import { Text } from '@/components/atoms';
import styles from './servicesBadge.module.scss';

const ServicesBadge = ({ icon, title, label }) => (
  <div className={styles.container}>
    <div className={styles.badge}>{icon}</div>
    <Text
      variant="span"
      textSize="l"
      textColor="var(--color-primary)"
      weight="bolder"
      style={{ width: '50%' }}
    >
      {title}
    </Text>
    <Text
      variant="span"
      textSize="m"
      textColor="white"
      className={styles.label}
    >
      {label}
    </Text>
  </div>
);

ServicesBadge.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default ServicesBadge;
